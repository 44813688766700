<template>
  <common-form
    ref="form"
    url="user"
    :edit="edit"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>

  export default {
    data() {
      return {
        edit: false,
        treeData: [],
        roleOptions:[],
        deptTreeData:[]
      }
    },
    computed: {
      items() {
        return [
          {
            type: "input",
            name: "用户名",
            key: "username",
            required: true,
            min:4,
            max:50,
            disable: this.edit
          },
          {
            type: "password",
            name: "登录密码",
            key: "password",
            // required: true,
            // min:6,
            // max:50,
          },
          {
            type: "input",
            name: "邮箱",
            key: "email",
          },
          {
            type: "input",
            name: "手机号",
            key: "mobile",
          },
          {
            type: "select",
            name: "角色",
            key: "roleId",
            required: true,
            options: this.roleOptions
          },
          // {
          //   type: "tree-select",
          //   name: "部门",
          //   key: "deptId",
          //   treeData: this.deptTreeData
          // },
          {
            type: "radio-button",
            name: "性别",
            key: "ssex",
            options: [
              {name:"保密",value:"0"},
              {name:"男",value:"1"},
              {name:"女",value:"2"},
            ]
          },
          {
            type: "input",
            name: "备注",
            key: "description",
          },
          {
            type: "radio-button",
            name: "状态",
            key: "status",
            options: [
              {name:"有效",value:"1"},
              {name:"锁定",value:"0"},
            ],
            hint:"锁定后该账号无法登录"
          },
        ]
      }
    },
    methods: {
      show(model = {}) {

        this.edit = model.userId > 0
        if(this.edit){
          model.password = ""
          model.createTime=null
          model.modifyTime=null
          model.lastLoginTime=null
        }else{
          model.ssex="0"
          model.status="1"
        }


        this.$refs.form.show(model)

        this.$get('role').then((r) => {
          let options = []
          r.data.rows.forEach(item=>{
            options.push({name:item.roleName,value:item.roleId})
          })
          this.roleOptions = options
        })

        this.$get('dept').then((r) => {
          this.deptTreeData = r.data.rows.children
        })



      },

    }

  }
</script>
